<template>
    <div>
		<b-modal ref="ModalSendInvoice" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("action.envoyer") }}
				</template>
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-else class="row">
				<div class="col">
					<div v-if="form_message !== ''" class="col-12 alert alert-warning">{{ form_message }}</div>

					<div class="col-12">
						<div class="form-group">
							<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
							<e-select
								v-model="template"
								id="model_id"
								track-by="model_label"
								label="model_label"
								:placeholder="labelTitleTemplate"
								:deselectLabel="deselectLabel"
								:selectedLabel="selectedLabel"
								:options="templates"
								:searchable="true"
								:allow-empty="false"
								:show-labels="false"
								group-values="templates"
                                group-label="templategroup_label"
                                :group-select="false"
								@input="setModel"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>
						<div v-if="template">
							<a href="#" @click.prevent="showModel">{{ $t('model.afficher_model') }}</a>
						</div>
						
						<div class="form-group mt-4">
							<label for="mailtype_label">{{ $t('model.expeditor_label') }} *</label>
							<e-select
								v-model="finalExpeditor"
								id="expeditor_id"
								:placeholder="labelTitleExpeditor"
								:options="finalExpeditors"
								:searchable="true"
								:allow-empty="false"
								:show-labels="false"        
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ option }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>

						<b-alert show variant="warning" class="mt-2" v-if="nomail">
							<h6>{{ $t("model.personnaliser_email") }}</h6>
						</b-alert>

						<div class="form-group">
							<label for="commentaire" class="col-form-label">{{ $t("monte.commentaire") }}</label>
							<textarea id="commentaire" class="form-control" rows="3" v-model="commentaire"></textarea>
						</div>
						<div class="mb-3">
							<b-form-checkbox
							id="send_mail_sender"
							v-model="send_mail_sender"
							:value="true"
							:unchecked-value="false"
							>
							{{ $t("monte.envoie_mail_sender") }}
							</b-form-checkbox>
						</div>

						<div class="form-group">
							<span>
								{{ $t('global.destinataires') }}: {{ destinataires_mails }}
							</span>
						</div>

						<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("action.envoyer") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
					</div>
				</div>
				<div v-if="show_model" class="col-12 col-lg-6">
					<div v-if="model_selected" class="border-2 px-3 py-3" v-html="model_selected"></div>
					<LoadingSpinner v-else class="col-12" />
				</div>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Accounting from '@/mixins/Accounting.js'
import Contact from '@/mixins/Contact.js'
import Invoice from '@/mixins/Invoice.js'
import Model from "@/mixins/Model.js"
import xss from 'xss'
import Vue from 'vue'

export default {
	name: "ModalSendInvoice",
	mixins: [Accounting, Contact, Invoice, Model],
	data () {
		return {
			templates: [],
			finalExpeditors: [],
			send_mail_sender: false,
			template: null,
			finalExpeditor: null,
			processing: false,
			invoices_ids: null,
			author_id: null,
			form_message: '',
			canChooseName: true,
			nomail: false,
			addMailToLicence: this.getTrad("model.personnaliser_email"),
			commentaire: '',
			destinataires_mails: '',
			modal_loaded: false,
			model_selected: null,
			accountingplan_id: null,
			show_model: false,

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc"),
			labelTitleExpeditor: this.getTrad("model.search_expeditor"),
		}
	},
	methods: {
		async init_component() {
			this.processing = false

			this.model_selected = null
			this.template = null
			this.show_model = false

			let accounting_plan = await this.loadAccountingPlanByEntity(this.author_id)
			const invoice_templates = await this.loadAllModel(accounting_plan.accountingplan_id, ['accounting_plan'], 'invoice_notification')
			const unpaid_templates = await this.loadAllModel(accounting_plan.accountingplan_id, ['accounting_plan'], 'unpaid_invoice_notification')
			this.templates = [
				{
					templategroup_label: this.$t('compta.facture_notif'),
					templates: invoice_templates
				},
				{
					templategroup_label: this.$t('compta.relance_notif'),
					templates: unpaid_templates
				},
			]
			this.accountingplan_id = accounting_plan.accountingplan_id

			if(this.invoices_ids.split(',').length == 1) {
				await this.getInvoiceById(this.invoices_ids.split(',')[0])
				.then(async invoice => {
					if(invoice && invoice.tiers) {
						let tiers_mail = []
						if(invoice.tiers.tiers_mail != '') {
							tiers_mail.push(invoice.tiers.tiers_mail)
						}
						const comptables_mails = await this.getContactByTiersAndByFonction(invoice.tiers.tiers_id, 'COMPTA')
						const all_mails = tiers_mail.concat(comptables_mails)
						this.destinataires_mails = all_mails.join(', ')
					}
				})
			}

			const fromAddress = await this.loadAllFromAddress()
			await this.FormatFromAddress(fromAddress)

			let modal = document.getElementsByClassName('modal-dialog')[0]
			modal.classList.remove('modal-xxl')

			this.modal_loaded = true
		},
		async FormatFromAddress(fromAddress){
			let array_mail = []
			let array_name = []
			this.finalExpeditors = []
			const selected = fromAddress["selected"]
			delete fromAddress["selected"]

			for (const [key, value] of Object.entries(fromAddress)){				
				if (value.licenceconfig_key === "from_address"){
					if (value.licenceconfig_value != '' || value.licenceconfig_value != null){
						const configValueAddress = value.licenceconfig_value.split(',')
						for (const address of configValueAddress){
							array_mail.push(address.trim())
						}
					} 					
				}
				if (value.licenceconfig_key === "from_name" ){
					if (value.licenceconfig_value != ''|| value.licenceconfig_value != null){
						const configValueName = value.licenceconfig_value.split(',')
						for (const name of configValueName){
							array_name.push(name.trim())
						}
					} 
				}
				if(array_mail.length > 0 && array_name.length > 0){
					if(array_name[0] === 'Groomy'){
						this.nomail = true
					}
					break;
				}
			}

			for (const index in array_mail){
				// if(array_mail[index] === selected["email"]){
				// 	this.finalExpeditors.unshift(array_mail[index]+ " / " + array_name[index])
				// } else {
				this.finalExpeditors.push(array_mail[index]+ " / " + array_name[index])
				// }
			}

			this.finalExpeditor = this.finalExpeditors[0]
		},
		openModal(invoices_ids, author_id) {
			this.invoices_ids = invoices_ids
			this.form_message = ''
			if(this.invoices_ids.split(',').length > 5) {
				this.form_message = this.getTrad("global.warning_lots")
			}
			this.author_id = author_id
			this.$refs.ModalSendInvoice.show()
		},
		closeModal() {
			this.$refs.ModalSendInvoice.hide()
		},
		async checkForm() {
			if(this.template) {
				this.processing = true
				await this.sendMailInvoices(this.invoices_ids, this.notificationType, this.template.model_id, this.commentaire, this.finalExpeditor, this.send_mail_sender)
				.then(result => {
					if(result) {
						this.processing = false
						this.successToast('monte.send_success_invoice')
						this.$emit('submit')
						this.closeModal()
					}
					else {
						this.processing = false
						this.$emit('submit')
						this.closeModal()
					}
				})
			}
			else {
				this.failureToast("formulaire.erreur_champs_non_remplis")
			}
		},
		async setModel() {
			this.model_selected = null

			if(this.template) {
				const models = await this.loadModel(this.accountingplan_id, this.template.model_id, 'accounting_plan')
				const templates = await this.loadTemplate(this.accountingplan_id, this.template.model_id, 'accounting_plan', Vue.i18n.locale())
				const zone = models[0].zone.find(zone => zone.zonetype.modelzonetype_code == this.notificationType + '_body')
				this.model_selected = templates.find(temp => temp.modeltemplate_modelzone == zone.modelzone_id).modeltemplate_content
			}
		},
		showModel() {
			this.show_model = true
			let modal = document.getElementsByClassName('modal-dialog')[0]
			modal.classList.add('modal-xxl')
		},
		cleanHTML(input) {
			return xss(input)
		}
	},
	computed: {
		notificationType(){
			const typeOfTemplate = this.templates.find(t => t.templates.find(e => e.model_id === this.template.model_id))
			return typeOfTemplate.templategroup_label === this.$t('compta.relance_notif') ? 'unpaid_invoice_notification' :'invoice_notification' 
		}
	},
	components: {
		LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
	}
}
</script>